import { RequestsService } from './../../services/requests.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {

  private id: number;
  public userInfo: any;
  public canLoadUserInfo = false;
  public userGames: any = [];
  public userOffsprings: any = [];
  public dobInHeader: number;
  private extras: any = {};
  public feelings: any = [];
  public canShowFeeling: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public requestsService: RequestsService,
    private router: Router,
    private paginationRequest: RequestPaginationSuperstructureService
  ) {
    this.route.queryParams.subscribe( params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.extras = this.router.getCurrentNavigation().extras.state;
      }
    })
  }

  ngOnInit() {
    this.getId();
  }

  getFeelings() {
    this.paginationRequest.getRequest(`/seniors/${this.id}/senior_feelings`).subscribe(
      data => {
        this.feelings = data;
        this.canShowFeeling = true;
      },
      err => {
      }
    );
  }

  getId() {
    this.route.queryParams.subscribe(params => {
      this.id = this.route.snapshot.params["id"];
    });
    this.getSeniorInfo();
    this.getFeelings();
  }

  getSeniorInfo() {
    this.requestsService.getRequest('/seniors/' + this.id).subscribe(
      data => {
        this.userInfo = data['user'];
        this.extras['company_id'] = data['user'].institution.company_id;
        this.extras['community_id'] = data['user'].institution.id;
        this.id = data['user'].id;
        this.userOffsprings = data['offsprings'];
        this.userGames = data['played_games']
        this.canLoadUserInfo = true;
        this.countYear();
      }
    );
  }

  countYear() {
    let today = new Date().getFullYear();
    let birthd = new Date(this.userInfo.dob).getFullYear();
    let res = today - birthd;
    this.dobInHeader = res
  }

  goToEditReditent() {
    this.router.navigate(['/home/company-info/company-facilities/' + this.userInfo.institution.company_id + '/dashboard/' + this.userInfo.institution.id + '/edit-resident/' + this.id]);
  }
}
