import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { RandomColor } from 'angular-randomcolor'
import { SharedDataService } from '../../services/shared-data.service';

@Component({
  selector: 'app-institutions-for-company-diagram',
  templateUrl: './institutions-for-company-diagram.component.html',
  styleUrls: ['./institutions-for-company-diagram.component.scss']
})
export class InstitutionsForCompanyDiagramComponent implements OnInit {

  @Input() institutions = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['name', 'played_games_count'];
  public facilities: any;
  public currentColor: any;
  public dataSource: any;
  public newListOfInstitut: any;
  public hideDiagram = false;

  constructor(
    private sharedData: SharedDataService
  ) {
    this.sharedData.getData().subscribe((info: any) => {
      this.institutions = info
      this.dataSource = new MatTableDataSource(this.institutions);
      this.dataSource.paginator = this.paginator;
      if (info) {
        this.countPercentOfGames(info);
      }
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.institutions);
    this.dataSource.paginator = this.paginator;
    this.countPercentOfGames(this.institutions);
  }

  countPercentOfGames(institutions) {
    let sumOfGames: number = 0;
    let arr = [];
    institutions.forEach(institut => {
      sumOfGames += institut.played_games_count;
    })
    institutions.forEach(institut => {
      let percentOfGames = Math.round((institut.played_games_count * 100) / sumOfGames);
      institut['percentOfGames'] = percentOfGames;
      arr.push(percentOfGames)
    })
    this.drawDiagramWithPercent(arr);
    this.setColor()
  }

  drawDiagramWithPercent(arr) {
    for (let i = 0; i < arr.length; i++) {
      let hundred = 100 - arr[i];
      let id = 'institut' + i;
      setTimeout(() => {
        document.getElementById(id).setAttribute('stroke-dasharray', `${arr[i]} ${hundred}`);
        this.setOffset(arr);
      }, 0);
    }
  }

  setOffset(arr) {
    let prevSum = arr[0];
    let offset = 25;
    setTimeout(() => {
      document.getElementById('institut0').setAttribute('stroke-dashoffset', `${offset}`)
    }, 0);
    for (let i = 1; i < arr.length; i++) {
      let formula = 100 - prevSum + offset;
      let id = 'institut' + i;
      setTimeout(() => {
        document.getElementById(id).setAttribute('stroke-dashoffset', `${formula}`)
      }, 0);
      prevSum += arr[i];
    }
  }

  setColor() {
    for (let i = 0; i < this.institutions.length; i++) {
      let id = 'institut' + i;
      let itemId = 'item';
      const randColor = RandomColor.generateColor();
      this.institutions[i]['color'] = randColor;
      setTimeout(() => {
        document.getElementById(id).style.stroke = this.institutions[i].color;
      }, 0)
    };
  }
}
