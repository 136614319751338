import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-relative-table',
  templateUrl: './relative-table.component.html',
  styleUrls: ['./relative-table.component.scss']
})
export class RelativeTableComponent implements OnInit {

  @Input() userOffsprings = [];
  public dataSource: any;
  displayedColumns: string[] = ['name', 'relationship', 'email', 'number'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor() { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.userOffsprings);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
