import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestsService } from 'src/app/services/requests.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidatorsService } from 'src/app/services/validators.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-institutions-for-company',
  templateUrl: './institutions-for-company.component.html',
  styleUrls: ['./institutions-for-company.component.scss']
})
export class InstitutionsForCompanyComponent implements OnInit {

  public id: any;
  public institutions: any;
  public loading = false;
  public companyName: any;
  public canLoadName = false;
  public newInstitutionsList: any;
  public emptyData = false;
  public newInstitut: FormGroup;
  public changeStatusInstitut = false;
  public submitted = false;
  public hideDiagram = true;

  constructor(
    private route: ActivatedRoute,
    private requestsService: RequestsService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    public validators: ValidatorsService,
    private modalService: NgbModal,
    public authenticationService: AuthenticationService,
    private paginationRequest: RequestPaginationSuperstructureService
  ) { }

  ngOnInit() {
    this.getId();
    this.initForm();
  }

  getId() {
    this.route.queryParams.subscribe(params => {
      this.id = this.route.snapshot.params["id"];
      this.getCompanyName();
      this.addStatusToInstitution();
    });
  }

  clearInput() {
    this.newInstitut.reset();
    this.changeStatusInstitut = false;
  }

  addStatusToInstitution() {
    this.paginationRequest.getRequest('/companies/' + this.id + '/institutions').subscribe(
      data => {
        this.institutions = data;
        let sum = 0;
        this.institutions.forEach(institut => {
          sum += institut.played_games_count;
        })
        if (sum == 0) {
          this.hideDiagram = false;
        } else {
          this.hideDiagram = true;
        }
        if (this.institutions.length) {
          this.loading = true;
        } else {
          this.emptyData = true;
        }
      },
      err => {
        this.toastr.error("Can't get institutions for this company", "Error", {
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

  getCompanyName() {
    this.requestsService.getRequest('/companies/' + this.id).subscribe(
      data => {
        this.companyName = data;
        this.canLoadName = true;
      },
      err => {
        this.toastr.error("This information is not available for you", "Error", {
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

  initForm() {
    this.newInstitut = this.formBuilder.group({
      name: ['', [Validators.required, this.validators.textValidator]]
    })
    this.onChanges();
  }

  onChanges() {
    this.newInstitut.valueChanges.subscribe(
      val => {
        this.changeStatusInstitut = true;
      }
    )
  }

  createInstitut() {
    let value = this.newInstitut.value;
    this.submitted = true;
    if (!this.newInstitut.invalid) {
      let instit = {
        institution: {}
      }
      instit.institution['name'] = value.name;
      instit.institution['company_id'] = this.id;
      this.requestsService.postRequest('/institutions', instit).subscribe(
        data => {
          this.changeStatusInstitut = false;
          this.modalService.dismissAll();
          this.addStatusToInstitution();
          this.emptyData = false;
          this.toastr.success('Institution was created successfully', 'Congrats', {
            positionClass: 'toast-bottom-right'
          })
        }
      )
    }
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

}
