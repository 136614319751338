import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/services/requests.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ValidatorsService } from 'src/app/services/validators.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-create-senior',
  templateUrl: './create-senior.component.html',
  styleUrls: ['./create-senior.component.scss']
})
export class CreateSeniorComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public userWrap = {
    user: {}
  };
  private id: number;
  public relatives = [];
  public newFamily: any;
  public maxDate: any;
  private currentUser: any;

  constructor(
    public requestService: RequestsService,
    public authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private validators: ValidatorsService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getId();
    this.initForm();
    this.setMaxDay();
  }

  setMaxDay() {
    let today = new Date();
    this.maxDate = today;
  }

  onSubmit() {
    this.userWrap.user = this.authenticationService.registerForm.value;
    this.submitted = true;
    if (!this.authenticationService.registerForm.invalid) {
      this.createFamily();
    }
  }

  getId() {
    this.activeRoute.paramMap.pipe(
      map(params => params.get('id'))).subscribe(
        data => {
          this.id = +data;
        }
      );
  }

  initForm() {
    this.authenticationService.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, this.validators.textValidator]],
      dob: ['', Validators.required],
      last_name: ['', [Validators.required, this.validators.textValidator]],
      type: 'Senior',
      institution_id: this.id,
      email: ['', [Validators.required, this.validators.emailValidator]],
      password: '',
      relatives: this.formBuilder.array([])
    });
  }

  get formData() {
    return this.authenticationService.registerForm.get('relatives') as FormArray;
  }

  relativeControls(i) {
    if (!this.authenticationService.registerForm.controls.relatives['controls'][i]['controls'].phone.pristine) {
      this.authenticationService.registerForm.controls.relatives['controls'][i].controls.phone.setValidators([this.validators.numberValidator]);
      this.authenticationService.registerForm.controls.relatives['controls'][i].controls.phone.updateValueAndValidity();
    }
    if (this.authenticationService.registerForm.controls.relatives['controls'][i]['controls'].phone.value == '') {
      this.authenticationService.registerForm.controls.relatives['controls'][i]['controls'].phone.markAsPristine();
      this.authenticationService.registerForm.controls.relatives['controls'][i].controls.phone.clearValidators();
      this.authenticationService.registerForm.controls.relatives['controls'][i].controls.phone.updateValueAndValidity();
    }
    return this.authenticationService.registerForm.controls.relatives['controls'][i]['controls'];
  }

  addRelative(item?) {
    this.relatives.unshift(item);
    this.formData.push(this.formBuilder.group({
      first_name: ['', [Validators.required, this.validators.textValidator]],
      relationship: ['', [Validators.required, this.validators.textValidator]],
      phone: [''],
      email: ['', [Validators.required, this.validators.emailValidator]],
      last_name: ['', [Validators.required, this.validators.textValidator]]
    }));
    this.scrollToNewFamilyMember();
  }

  removeRelative(i) {
    let id: any;
    for (let j = 0; j < this.authenticationService.registerForm.controls['relatives']['controls'].length; j++) {
      if (i == j) {
        id = this.authenticationService.registerForm.controls['relatives']['controls'][j].value.id;
        if (id) {
          this.requestService.deleteRequest('/users/' + id).subscribe(
            data => {
              this.formData.removeAt(i);
            }
          )
        } else if (!id) {
          this.formData.removeAt(i);
        }
      }
    }
  }

  createFamily() {
    if (!this.newFamily) {
      this.requestService.postRequest('/families', { family: { name: this.userWrap.user['last_name'] } }).subscribe(
        data => {
          this.newFamily = data;
          this.addFieldsToSenior();
          let relativesList = this.userWrap.user['relatives'];
          let relativeWrapp = {
            offspring: {}
          };
          this.addFieldsToRelatives(relativesList);
          relativesList.forEach(member => {
            relativeWrapp.offspring = member;
          });
          this.loading = true;
          this.createPatient(relativesList, relativeWrapp)
        }
      )
    } else {
      this.addFieldsToSenior();
      let relativesList = this.userWrap.user['relatives'];
      let relativeWrapp = {
        offspring: {}
      };
      this.addFieldsToRelatives(relativesList);
      relativesList.forEach(member => {
        relativeWrapp.offspring = member;
      });
      this.loading = true;
      this.createPatient(relativesList, relativeWrapp)
    }
  }

  addFieldsToSenior() {
    this.userWrap.user['family_id'] = this.newFamily.id;
    this.userWrap.user['family_name'] = this.newFamily.name;
    this.userWrap.user['password'] = `${this.userWrap.user['last_name']}` + 123;
  }

  createPatient(relativesList, relativeWrapp) {
    if (!this.currentUser) {
      if (!this.authenticationService.registerForm.invalid) {
        this.setDobOfPatient(this.userWrap.user)
        this.requestService.postRequest('/users/', this.userWrap).subscribe(
          data => {
            this.currentUser = data;
            if (relativesList.length) {
              relativesList.forEach(member => {
                member['senior_id'] = this.currentUser['id']
                relativeWrapp.offspring = member;
                this.requestService.postRequest('/offsprings', relativeWrapp).subscribe(
                  data => {
                    this.toastr.success('Resident was created successfully', 'Congrats', {
                      closeButton: true,
                      positionClass: 'toast-bottom-right'
                    })
                    this.requestService.backNav();
                  },
                  err => {
                    for (let key in err) {
                      this.toastr.error(key + ' ' + relativeWrapp.offspring.email + ' ' + err[key], 'Family Member ' + member.first_name, {
                        closeButton: true,
                        positionClass: 'toast-bottom-right'
                      })
                    }
                  }
                )
              })
            } else {
              this.toastr.success('Resident was created successfully', 'Congrats', {
                closeButton: true,
                positionClass: 'toast-bottom-right'
              })
              this.requestService.backNav();
            }
          },
          err => {
            for (let key in err) {
              this.toastr.error(key + ' ' + this.userWrap.user['email'] + ' ' + err[key], 'Resident', {
                closeButton: true,
                positionClass: 'toast-bottom-right'
              })
            }
          }
        )
      }
    } else {
      relativesList.forEach(member => {
        member['senior_id'] = this.currentUser['id'];
        relativeWrapp.offspring = member;
        this.requestService.postRequest('/offsprings', relativeWrapp).subscribe(
          data => {
            this.toastr.success('Resident was created successfully', 'Congrats', {
              closeButton: true,
              positionClass: 'toast-bottom-right'
            })
            this.requestService.backNav();
          },
          err => {
            for (let key in err) {
              this.toastr.error(key + ' ' + relativeWrapp.offspring.email + ' ' + err[key], 'Family Member ' + member.first_name, {
                closeButton: true,
                positionClass: 'toast-bottom-right'
              })
            }
          }
        )
      })
    }
  }

  addFieldsToRelatives(relativeList) {
    relativeList.forEach(member => {
      member.type = 'Offspring';
      member.password = member.last_name + 123;
      member.family_id = this.newFamily.id;
      member.family_name = this.newFamily.name;
    });
  }

  setDobOfPatient(user) {
    let date = new Date(user['dob']).getDate();
    let year = new Date(user['dob']).getFullYear();
    let month = new Date(user['dob']).getMonth() + 1;
    user['dob'] = year + '-' + month + '-' + date;
  }

  openDialog(i): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this resident?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRelative(i);
      }
    });
  }

  scrollToNewFamilyMember() {
    let element = document.getElementsByClassName('row-cont');
    setTimeout(() => {
      element[0].scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
    }, 0);
  }
}
