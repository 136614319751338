import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { RequestsService } from '../../services/requests.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  showLoadingIndicator = true;
  public profileMenu = false;
  public infoUser: any;
  public infoUserStatus = false;

  public info = JSON.parse(localStorage.getItem('currentUser'));

  public hideMenu: boolean = false;

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private requestService: RequestsService
  ) { }

  ngOnInit() {
    this.userInfo();
  }

  showProfileMenu() {
    this.profileMenu = !this.profileMenu;
  }

  logOutSite() {
    this.authenticationService.logout();
    this.router.navigate(['sign-in']);
  }

  toMyProfile() {
    this.router.navigate(['home/my-profile']);
  }

  toAdminDashboard() {
    this.router.navigate(['/home/admin-dashboard'])
  }

  userInfo() {
    this.info = this.authenticationService.currentUserValue;
    this.requestService.getRequest('/users/' + this.info['user'].id).subscribe(
      data => {
        this.infoUser = data;
        this.infoUserStatus = true;
      }
    )
  }

  checkUserType() {
    if(this.info.user_type == 'InstitutionAdmin') {
      return "../../../assets/imgs/FacilityAdmin.png";
    } else if (this.info.user_type == 'CompanyAdmin') {
      return "../../../assets/imgs/CompanyAdmin.png";
    } else if (this.info.user_type == 'SuperAdmin') {
      return "../../../assets/imgs/SuperAdmin.png";
    }
  }

}
