import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { RequestsService } from '../../services/requests.service'
import { ValidatorsService } from 'src/app/services/validators.service';
import { ToastrService } from 'ngx-toastr';
import { PasswordValidatorService } from 'src/app/services/password-validator.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  private myData = this.authenticationService.currentUserValue;
  public submitted = false;
  public loaded = false;
  public submittedPassword = false;
  public userInfo: any;
  public showSuccess = false;
  public changeStatusSeniorData = false;
  public changeStatusSeniorPassword = false;
  private registerForm: FormGroup;

  constructor(
    public authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private requestServie: RequestsService,
    private validators: ValidatorsService,
    private toastr: ToastrService,
    private passwordValidator: PasswordValidatorService
  ) { }

  ngOnInit() {
    this.getUserInfo();
    this.initForm();
    this.onChangesPassword();
  }

  getUserInfo() {
    this.requestServie.getRequest('/users/' + this.myData['user'].id).subscribe(
      data => {
        this.userInfo = data;
        this.registerForm.patchValue({
          userData: {
            email: this.userInfo.email,
            first_name: this.userInfo.first_name,
            last_name: this.userInfo.last_name
          }
        });
        this.onChangesData();
      }
    )
  }

  initForm() {
    this.registerForm = this.formBuilder.group({
      userData: this.formBuilder.group({
        email: ['', [Validators.required, this.validators.emailValidator]],
        first_name: ['', [Validators.required, this.validators.textValidator]],
        last_name: ['', [Validators.required, this.validators.textValidator]]
      }),
      userPassword: this.formBuilder.group({
        current_password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator.passwordValidator]],
        password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator.passwordValidator]],
        password_confirmation: ['', [Validators.required]]
      })
    })
    this.loaded = true;
  }

  get userDataControls() {
    return this.registerForm.get('userData')['controls'] || this.loaded;
  }

  get userPasswordControls() {
    return this.registerForm.get('userPassword')['controls'];
  }

  get userData() {
    return this.registerForm.get('userData');
  }

  get userPassword() {
    return this.registerForm.get('userPassword');
  }

  onChangesData() {
    this.userData.valueChanges.subscribe(
      val => {
        this.changeStatusSeniorData = true;
      }
    )
  }

  onChangesPassword() {
    this.userPassword.valueChanges.subscribe(
      val => {
        this.changeStatusSeniorPassword = true;
      }
    )
  }

  saveSeniorChanges() {
    let fields = this.userData.value;
    let id = this.myData['user'].id
    this.submitted = true;
    if (!this.userData.invalid) {
      let personalDataFields = {
        user: {
          first_name: fields.first_name,
          last_name: fields.last_name,
          email: this.myData['user'].email,
          type: this.myData['user_type'],
          dob: this.myData['user'].dob
        }
      }
      this.sendChanges(id, personalDataFields)
    }
  }

  sendChanges(id, body) {
    this.requestServie.putRequest('/users/' + id, body).subscribe(
      data => {
        this.toastr.success('Changes were saved successfully. Reload page to see it.', 'Congrats', {
          positionClass: 'toast-bottom-right'
        })
        this.changeStatusSeniorData = false;
      },
      err => {
        this.toastr.error('Try again later', 'Something went wrong', {
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

  changePassword() {
    let fields = this.userPassword.value;
    this.submittedPassword = true;
    if (!this.userPassword.invalid) {
      let passwordFields = {
        user: {
          current_password: fields.current_password,
          password: fields.password,
          password_confirmation: fields.password_confirmation
        }
      }
      this.requestServie.putRequest('/users/update_password', passwordFields).subscribe(
        data => {
          this.changeStatusSeniorPassword = false;
          this.toastr.success('Changes were saved successfully.', 'Congrats', {
            positionClass: 'toast-bottom-right'
          });
        },
        err => {
          this.toastr.error('Try again later', 'Something went wrong', {
            positionClass: 'toast-bottom-right'
          })
        }
      )
    }
  }
}
