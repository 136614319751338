import { Injectable } from '@angular/core';
import { RequestsService } from './requests.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestPaginationSuperstructureService {

  constructor(private requestService: RequestsService) { }

  getRequest(path) {
    return new Observable<any>((observer) => {
      let mainData = [];
      let totalPages;
      let currentPage=1;
      let changePaginationInterval;
      changePaginationInterval = setInterval(()=>{
        this.requestService.getRequest(path+`?page=${currentPage}`).subscribe(
          data => {
            data['total_pages'] ? totalPages = data['total_pages'] : clearInterval(changePaginationInterval);
            data['records'].length > 0 ? data['records'].forEach(element => {mainData.push(element)}) : null
          },
          err => {
          },
          () => {
            if (currentPage < totalPages) {
              currentPage++;
            } else {
              clearInterval(changePaginationInterval);
              observer.next(mainData)
            }
          }
        )
      },200)
    })
  }
}
