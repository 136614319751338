import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { RequestsService } from '../services/requests.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private requestsService: RequestsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (window.innerWidth < 1024) {
      this.authenticationService.currentUserSubject.next(null);
      localStorage.removeItem('currentUser');
      this.router.navigate(['/mobile-warn']);
      return false;
    }
    if (currentUser) {
      if (currentUser['user_type'] == "CompanyAdmin") {
        let companyId = state.url.split('/')[4];
        let facilityId = state.url.split('/')[6];
        if (state.url == "/home/company-info") {
          this.router.navigate(['/home/company-info/company-facilities/' + currentUser['user']['user_company_id']]);
          return false;
        } else if (state.url == '/home/company-info/company-facilities/' + companyId && companyId != currentUser['user']['user_company_id']) {
          this.router.navigate(['/home/company-info/company-facilities/' + currentUser['user']['user_company_id']]);
        } else if (state.url == '/home/company-info/company-facilities/' + companyId + '/dashboard/' + facilityId) {
          if (Number(companyId) != currentUser['user']['user_company_id']) {
            this.router.navigate(['/home/company-info/company-facilities/' + currentUser['user']['user_company_id']]);
          } else if (!Number(companyId)) {
            this.router.navigate(['/home/company-info/company-facilities/' + currentUser['user']['user_company_id']]);
          } else if (!Number(facilityId)) {
            this.router.navigate(['/home/company-info/company-facilities/' + currentUser['user']['user_company_id']]);
          }
          this.requestsService.getRequest('/companies/' + currentUser['user']['user_company_id'] + '/institutions').subscribe(
            data => {
              let arr: any = data;
              let res = arr.find(item => item.id == Number(facilityId));
              if (!res) {
                this.router.navigate(['/home/company-info/company-facilities/' + currentUser['user']['user_company_id']]);
              }
            }
          )
        }
      } else if (currentUser['user_type'] == "InstitutionAdmin") {
        let facilityId = state.url.split('/')[6];
        let companyId = state.url.split('/')[4];
        if (state.url == "/home/company-info") {
          this.router.navigate(['home/company-info/company-facilities/' + currentUser['user']['user_company_id'] + '/dashboard/' + currentUser['user']['institution_id']]);
          return false;
        } else if (state.url == '/home/company-info/company-facilities/' + companyId || !Number(companyId)) {
          this.router.navigate(['home/company-info/company-facilities/' + currentUser['user']['user_company_id'] + '/dashboard/' + currentUser['user']['institution_id']]);
        } else if (state.url == '/home/company-info/company-facilities/' + companyId + '/dashboard/' + facilityId) {
          if (Number(companyId) != currentUser['user']['user_company_id'] || !Number(companyId)) {
            this.router.navigate(['home/company-info/company-facilities/' + currentUser['user']['user_company_id'] + '/dashboard/' + currentUser['user']['institution_id']]);
          } else if (Number(facilityId) != currentUser['user']['institution_id'] || !Number(facilityId)) {
            this.router.navigate(['home/company-info/company-facilities/' + currentUser['user']['user_company_id'] + '/dashboard/' + currentUser['user']['institution_id']]);
          }
        }
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/sign-in']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      return true;
    } else {
      this.authenticationService.currentUserSubject.next(null);
      this.router.navigate(['/sign-in']);
      return false
    }
  }
}
