import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  private url = 'https://senior-games.herokuapp.com';
  // private url = 'http://localhost:3000'

  httpHeaders: any;
  data: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private loc: Location) {
      this.data = new BehaviorSubject<any>(null);
    }

  getRequest(path) {
    return this.http.get(`${this.url}` + path);
  }

  putRequest(path, body) {
    return this.http.put(`${this.url}` + path, body);
  }

  postRequest(path, body) {
    return this.http.post(`${this.url}` + path, body);
  }

  deleteRequest(path) {
    return this.http.delete(`${this.url}` + path);
  }

  backNav() {
    this.loc.back();
  }

  getData(): Observable<any> {
    return this.data.asObservable();
  }

  setData(isAuth):void {
    this.data.next(isAuth);
  }
}
