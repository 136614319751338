import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { PatientDetailsComponent } from './components/patient-details/patient-details.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { AuthGuard } from './helpers/auth.guard';
import { CreateSeniorComponent } from './components/create-senior/create-senior.component';
import { EditPatientComponent } from './components/edit-patient/edit-patient.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { HomeComponent } from './pages/home/home.component';
import { OffspringsComponent } from './pages/offsprings/offsprings.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { InstitutionsForCompanyComponent } from './components/institutions-for-company/institutions-for-company.component';
import { ManageAdminComponent } from './components/manage-admin/manage-admin.component';
import { MobileWarnComponent } from './components/mobile-warn/mobile-warn.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'company-info',
    pathMatch: 'full'
  },
  {
    path: 'mobile-warn',
    component: MobileWarnComponent,
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    pathMatch: 'full'
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    pathMatch: 'full'
  },
  {
    path: 'users/update_password/:token',
    component: OffspringsComponent
  },
  {
    path: 'modificator_redirect',
    component: RedirectComponent
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: HomeComponent,
    children: [
      {
        path: 'company-info',
        children: [
          {
            path: '',
            component: CompanyInfoComponent
          },
          {
            path: 'company-facilities/:id',
            data: {
              breadcrumb: 'Company communities'
            },
            children: [
              {
                path: '',
                component: InstitutionsForCompanyComponent,
              },
              {
                path: 'dashboard/:id',
                data: {
                  breadcrumb: 'Community dashboard'
                },
                children:[
                  {
                    path: '',
                    component: DashboardComponent
                  },
                  {
                    path: 'resident-details/:id',
                    component: PatientDetailsComponent,
                    pathMatch: 'full',
                    data: {
                      breadcrumb: 'Resident details'
                    }
                  },
                  {
                    path: 'edit-resident/:id',
                    component: EditPatientComponent,
                    data: {
                      breadcrumb: 'Edit Resident'
                    }
                  },
                  {
                    path: 'new-resident/:id',
                    component: CreateSeniorComponent,
                    data: {
                      breadcrumb: 'Create Resident'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        data: {
          breadcrumb: 'My profile'
        }
      },
      {
        path: 'admin-dashboard',
        component: ManageAdminComponent,
        data: {
          breadcrumb: 'Admins dashboard'
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/sign-in',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
