import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public registerForm: FormGroup;
  private url = 'https://senior-games.herokuapp.com';
  // private url = 'http://localhost:3000'

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(body) {
    return this.http.post<any>(`${this.url}` + '/users/sign_in', body).pipe(map(
      user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  register(body) {
    return this.http.post<any>(`${this.url}` + '/users/sign_up', body).pipe(map(
      user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }
    )
    );
  }

  logout() {
    localStorage.clear();
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  public validationError(errors) {
    let errorMessages = '';
    if (errors) {
      for (let key of Object.keys(errors)) {
        if (key == 'required') {
          errorMessages += 'This field is required. ';
        } else {
          if (errors[key].constructor === Array) {
            errorMessages += errors[key][0]
          } else if (key == 'minlength') {
            errorMessages += 'Length must be more then 8 letters. ';
          } else if (key == 'pattern') {
            errorMessages += 'Email is invalid. ';
          } else if (key == 'matDatepickerMax') {
            errorMessages += 'Date is not correct. Resident must be older then 30.';
          } else if (key == 'Mask error') {
            errorMessages += 'Phone number is not correct.';
          }
          else {
            errorMessages += errors[key];
          }
        }
      }
    }
    return errorMessages;
  }
}
