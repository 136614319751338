import { RequestsService } from './../../services/requests.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  public loading = false;
  public companiesList: any;

  constructor(
    private requestService: RequestsService,
    private toastr: ToastrService,
    private paginationRequest: RequestPaginationSuperstructureService
  ) { }

  ngOnInit() {
    this.getCompanies();
  }

  getCompanies() {
    this.paginationRequest.getRequest('/companies').subscribe(
      data => {
        this.companiesList = data;
        this.loading = true;
      },
      err => {
        this.toastr.error("Can't get companies", "Error", {
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

}
