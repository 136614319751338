import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-feeling-statistic',
  templateUrl: './feeling-statistic.component.html',
  styleUrls: ['./feeling-statistic.component.scss']
})
export class FeelingStatisticComponent implements OnInit {

  @Input() userFeelings = [];
  page = 1;
  pageSize = 5;
  collectionSize: any;

  displayedColumns: string[] = ['created_at', 'feeling'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource: any;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  get feelings() {
    return this.userFeelings
      .map((feeling, i) => ({id: i + 1, ...feeling}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  constructor() {
  }

  ngOnInit() {
    this.collectionSize = this.userFeelings.length;
    this.userFeelings = this.userFeelings.map((feeling) => {
      return {
        id: feeling.id,
        feeling: feeling.feeling,
        created_at: feeling.created_at
      };
    }).reverse();
    this.dataSource = new MatTableDataSource(this.userFeelings);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  parseIcon(img) {
    return `../../../assets/imgs/feelings/${img}.png`;
  }

  parseDate(old_date) {
    let date = new Date(old_date);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = 0 + dt;
    }
    if (month < 10) {
      month = 0 + month;
    }
    return `${month}/${dt}/${year}`;
  }

  parseMood(name) {
    switch(name) {
      case 'ok':
        return 'Ok';
      case 'sad':
        return "Sad";
      case "very_sad":
        return "Very Sad";
      case "pretty_good":
        return "Pretty Good";
      case "great":
        return "Great";
    }
  }
}
