import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { RandomColor } from 'angular-randomcolor'
import { RequestsService } from 'src/app/services/requests.service';

@Component({
  selector: 'app-company-diagram',
  templateUrl: './company-diagram.component.html',
  styleUrls: ['./company-diagram.component.scss']
})
export class CompanyDiagramComponent implements OnInit {

  @Input() companiesList: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['name', 'played_games_count'];
  public dataSource: any;

  constructor(
    public requestsService: RequestsService
  ) {
    this.requestsService.getData().subscribe((info: any) => {
      this.companiesList = info;
      this.dataSource = new MatTableDataSource(this.companiesList);
      this.dataSource.paginator = this.paginator;
      if (info) {
        this.countPercentOfGames(this.companiesList);
      }
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.companiesList);
    this.dataSource.paginator = this.paginator;
    this.countPercentOfGames(this.companiesList);
  }

  countPercentOfGames(companies) {
    let sumOfGames: number = 0;
    let arr = [];
    companies.forEach(company => {
      sumOfGames += company.played_games_count;
    })
    companies.forEach(company => {
      let percentOfGames = Math.round((company.played_games_count * 100) / sumOfGames);
      company['percentOfGames'] = percentOfGames;
      arr.push(percentOfGames);
    })
    this.drawDiagramWithPercent(arr);
    this.setColor();
  }

  drawDiagramWithPercent(arr) {
    for (let i = 0; i < arr.length; i++) {
      let hundred = 100 - arr[i];
      let id = 'company' + i;
      setTimeout(() => {
        document.getElementById(id).setAttribute('stroke-dasharray', `${arr[i]} ${hundred}`);
        this.setOffset(arr);
      }, 0);
    }
  }

  setOffset(arr) {
    let prevSum = arr[0];
    let offset = 25;
    setTimeout(() => {
      document.getElementById('company0').setAttribute('stroke-dashoffset', `${offset}`)
    }, 0);
    for (let i = 1; i < arr.length; i++) {
      let formula = 100 - prevSum + offset;
      let id = 'company' + i;
      setTimeout(() => {
        document.getElementById(id).setAttribute('stroke-dashoffset', `${formula}`)
      }, 0);
      prevSum += arr[i];
    }
  }

  setColor() {
    for (let i = 0; i < this.companiesList.length; i++) {
      let id = 'company' + i;
      const randColor = RandomColor.generateColor();
      this.companiesList[i]['color'] = randColor;
      setTimeout(() => {
        document.getElementById(id).style.stroke = this.companiesList[i].color;
      }, 0)
    };
  }

}
