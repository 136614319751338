import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PasswordValidatorService {

  constructor() { }

  passwordValidator(control: FormControl) {
    const value = control.value;
    const hasNum = /[0-9]/.test(value);
    const hasLowercaseLetter = /[a-z]/.test(value);
    const hasUppercaseLetter = /[A-Z]/.test(value);
    const passwordValid = hasNum && hasLowercaseLetter || hasUppercaseLetter;
    if (!passwordValid) {
      return { invalidPassword: 'Password must consist of numbers and letters' };
    }
    return null;
  }
}
