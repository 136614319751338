import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  data: BehaviorSubject<any>;

  constructor() { 
    this.data = new BehaviorSubject<any>(null);
  }

  getData(): Observable<any> {
    return this.data.asObservable();
  }

  setData(isAuth):void {
    this.data.next(isAuth);
  }
}
