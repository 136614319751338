import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RequestsService } from 'src/app/services/requests.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {

  public breadcrumbs: Breadcrumb[] = [];
  private currentUser = this.authenticationService.currentUserValue;
  public userInfo: any;
  company: any;
  community: any;
  resident: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requestsService: RequestsService,
    private authenticationService: AuthenticationService) {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
      if (event.id === 1) {
        if (localStorage.getItem('breadcrumb') && localStorage.getItem('breadcrumb').length) {
          this.breadcrumbs = JSON.parse(localStorage.getItem('breadcrumb'));
        }
      }
    })
  }

  ngOnInit() {
    this.getUserInfo();
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
    //get name from router parrams
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        if (this.router.getCurrentNavigation().extras.state.company) {
          this.company = this.router.getCurrentNavigation().extras.state.company;
          localStorage.setItem('company', JSON.stringify(this.router.getCurrentNavigation().extras.state.company))
        } else if (this.router.getCurrentNavigation().extras.state.community) {
          this.community = this.router.getCurrentNavigation().extras.state.community;
          localStorage.setItem('community', JSON.stringify(this.router.getCurrentNavigation().extras.state.community))
        } else if (this.router.getCurrentNavigation().extras.state.resident) {
          this.resident = this.router.getCurrentNavigation().extras.state.resident;
          localStorage.setItem('resident', JSON.stringify(this.router.getCurrentNavigation().extras.state.resident))
        }
      }
    });
    //get the child routes
    let children: ActivatedRoute[] = route.children;
    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }
    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length == 0) {
        continue;
      }
      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");
      //append route URL to URL
      url += `/${routeURL}`;
      //add breadcrumb
      let breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url: '/home/company-info' + url
      };
      if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] == 'Company communities') {
        breadcrumb = {
          label: this.company || JSON.parse(localStorage.getItem('company')),
          url: '/home/company-info' + url
        }
      } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] == 'Community dashboard') {
        breadcrumb = {
          label: this.community || JSON.parse(localStorage.getItem('community')),
          url: '/home/company-info' + url
        }
      } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] == 'Resident details') {
        breadcrumb = {
          label: this.resident + ' Details' || JSON.parse(localStorage.getItem('resident')) + ' Details',
          url: '/home/company-info' + url
        }
      } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] == 'Edit Resident') {
        breadcrumb = {
          label: 'Edit Resident ' + this.resident || 'Edit Resident ' + JSON.parse(localStorage.getItem('resident')),
          url: '/home/company-info' + url
        }
      }
      else if (routeURL == 'my-profile' || routeURL == 'admin-dashboard') {
        breadcrumb = {
          label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
          url: '/home' + url
        };
      }
      breadcrumbs.push(breadcrumb);
      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  getUserInfo() {
    this.requestsService.getRequest('/users/' + this.currentUser['user'].id).subscribe(
      data => {
        this.userInfo = data;
        let breadcrumb: Breadcrumb;
        if (this.currentUser['user_type'] == 'SuperAdmin') {
          breadcrumb = {
            label: 'Home',
            url: 'company-info'
          };
        } else if (this.currentUser['user_type'] == 'CompanyAdmin') {
          breadcrumb = {
            label: 'Home',
            url: 'company-info/company-facilities/' + this.currentUser['user'].user_company_id
          };
        } else if (this.currentUser['user_type'] == 'InstitutionAdmin') {
          breadcrumb = {
            label: 'Home',
            url: 'company-info/company-facilities/' + this.userInfo.user_company_id + '/dashboard/' + this.currentUser['user'].institution_id
          };
        }

        this.breadcrumbs.unshift(breadcrumb);
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
          //set breadcrumbs
          let root: ActivatedRoute = this.route.root;
          this.breadcrumbs = this.getBreadcrumbs(root);
          for (let i = 0; i < this.breadcrumbs.length; i++) {
            if (this.currentUser['user_type'] == 'CompanyAdmin') {
              if (this.breadcrumbs[i].url == '/home/' + breadcrumb.url) {
                this.breadcrumbs.splice(i, 1);
              }
            } else if (this.currentUser['user_type'] == 'InstitutionAdmin') {
              this.breadcrumbs.splice(0, this.breadcrumbs.length - 1);
              if (this.breadcrumbs[i].url == '/home/' + breadcrumb.url && this.breadcrumbs[i].label !== 'Home') {
                this.breadcrumbs.splice(i, 1);
              }
            }
          }

          //set breadcrumb to localstorage
          if (localStorage.getItem('currentUser')) {
            localStorage.setItem('breadcrumb', JSON.stringify(this.breadcrumbs));
          }
          this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
        });
      }
    )
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}