import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { RequestsService } from 'src/app/services/requests.service';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { element } from 'protractor';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  displayedColumns: string[] = ['name', 'assestment', 'played_games_count', 'status', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource: any;
  private id: number;
  public seniors: any = [];
  public institutInfo: any;
  public emptySeniors = false;
  public institutInfoCanLoad = false;
  navExtras: NavigationExtras

  constructor(
    private route: ActivatedRoute,
    private requestService: RequestsService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private paginationRequest: RequestPaginationSuperstructureService
  ) { }

  ngOnInit() {
    this.getId();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  getId() {
    this.route.queryParams.subscribe(params => {
      this.id = this.route.snapshot.params["id"];
      this.getPatientList();
      this.institutionInfo();
    });
  }

  institutionInfo() {
    this.requestService.getRequest('/institutions/' + this.id).subscribe(
      data => {
        this.institutInfo = data;
        this.institutInfoCanLoad = true;
      },
      err => {
        this.toastr.error("Can't get this institutions", 'Error', {
          positionClass: 'toast-bottom-right'
        });
      }
    )
  }

  getPatientList() {
    this.paginationRequest.getRequest('/institutions/' + this.id + '/seniors').subscribe(
      data => {
        this.seniors = data;
        this.seniors.reverse();
        if (!this.seniors.length) {
          this.emptySeniors = true;
          this.dataSource = new MatTableDataSource([]);
        } else {
          this.seniors.forEach(senior => {
            senior['status'] = 'Stable';
            this.getFullDate(senior);
            this.dataSource = new MatTableDataSource(this.seniors);
            this.dataSource.paginator = this.paginator;
          });
        }
      },
      err => {
        this.toastr.error("Can't get residents for this institution", 'Error', {
          positionClass: 'toast-bottom-right'
        });
      }
    )
  }

  getFullDate(senior) {
    let fullDate: any;
    let UTC = new Date(senior['created_at']).getTimezoneOffset() / 60;
    let date = new Date(senior['created_at']).getMonth() + 1 + '/' + new Date(senior['created_at']).getDate() + '/' + new Date(senior['created_at']).getFullYear();
    let time = new Date(senior['created_at']).getHours() + UTC + ':' + new Date(senior['created_at']).getMinutes();
    if (new Date(senior['created_at']).getHours() + UTC >= 13) {
      if (new Date(senior['created_at']).getHours() + UTC == 13) {
        fullDate = date + ' ' + 1 + ':' + new Date(senior['created_at']).getMinutes() + 'pm';
        senior['time'] = fullDate;
      } else if (new Date(senior['created_at']).getHours() + UTC >= 14) {
        fullDate = date + ' ' + (new Date(senior['created_at']).getHours() + UTC - 12) + ':' + new Date(senior['created_at']).getMinutes() + 'pm';
        senior['time'] = fullDate;
      }
    } else {
      fullDate = date + ' ' + time + 'am';
      senior['time'] = fullDate;
    }
  }

  goSeniorInfo(element) {
    this.navExtras = {
      state: {
        resident: element.first_name + ' ' + element.last_name,
        company_id: this.institutInfo.company_id,
        community_id: this.id
      }
    }
    this.router.navigate(['/home/company-info/company-facilities/' + this.institutInfo.company_id + '/dashboard/' + this.id + '/resident-details/' + element.id],this.navExtras);
  }

  editPatient(element) {
    this.navExtras = {
      state: {
        resident: element.first_name + ' ' + element.last_name
      }
    }
    this.router.navigate(['/home/company-info/company-facilities/' + this.institutInfo.company_id + '/dashboard/' + this.id + '/edit-resident/' + element.id],this.navExtras);
  }

  deletPatient(element) {
    this.requestService.deleteRequest('/users/' + element.id).subscribe(
      data => {
        this.paginationRequest.getRequest('/institutions/' + this.id + '/seniors').subscribe(
          data => {
            this.dataSource.data = data;
            this.toastr.success('Deleting resident successfully', '', {
              positionClass: 'toast-bottom-right'
            });
            this.getPatientList();
          },
          err => {
            this.toastr.error("Can't delete, try again later", 'Error', {
              positionClass: 'toast-bottom-right'
            });
          }
        );
      }
    )
  }

  createSenior() {
    this.router.navigate(['/home/company-info/company-facilities/' + this.institutInfo.company_id + '/dashboard/' + this.id + '/new-resident/' + this.id]);
  }

  openDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this resident?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletPatient(element);
      }
    });
  }

}
