import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { PasswordValidatorService } from 'src/app/services/password-validator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { RequestsService } from 'src/app/services/requests.service';

@Component({
  selector: 'app-offsprings',
  templateUrl: './offsprings.component.html',
  styleUrls: ['./offsprings.component.scss']
})
export class OffspringsComponent implements OnInit {

  public submitted = false;
  public loading = false;
  public dataWrapp = {
    user: {}
  }
  private token: any;

  constructor(
    public authenticationService: AuthenticationService,
    public formbuilder: FormBuilder,
    public passwordValidator: PasswordValidatorService,
    public activeRoute: ActivatedRoute,
    public requestService: RequestsService,
    public router: Router
  ) { }

  ngOnInit() {
    this.initForm();
    this.getToken();
  }

  getToken() {
    this.activeRoute.paramMap.pipe(
      map(params => params.get('token'))).subscribe(
        data => {
          this.token = data;
        }
      );
  }

  initForm() {
    this.authenticationService.registerForm = this.formbuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator.passwordValidator]],
      password_confirmation: ['', Validators.required]
    })
  }

  onSubmit() {
    this.submitted = true;
    if (!this.authenticationService.registerForm.invalid) {
      this.loading = true;
      this.changePassword();
    } else {
    }
  }

  changePassword() {
    this.dataWrapp.user['reset_password_token'] = this.token;
    this.dataWrapp.user['password'] = this.authenticationService.registerForm.controls.password.value;
    this.dataWrapp.user['password_confirmation'] = this.authenticationService.registerForm.controls.password_confirmation.value;
    this.requestService.putRequest('/users/password', this.dataWrapp).subscribe(
      data => {
        this.router.navigate(['/sign-in'])
      },
      err => { }
    )
  }
}
