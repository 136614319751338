import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidatorsService } from 'src/app/services/validators.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { map } from 'rxjs/operators';
import { RequestsService } from 'src/app/services/requests.service';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from '../../services/shared-data.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatSort} from '@angular/material/sort';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-institutions-for-company-statistic',
  templateUrl: './institutions-for-company-statistic.component.html',
  styleUrls: ['./institutions-for-company-statistic.component.scss']
})
export class InstitutionsForCompanyStatisticComponent implements OnInit {

  @Input() institutions: any = [];
  @Input() companyInfo:any = {};
  displayedColumns: string[] = ['name', 'played_games_count'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource: any;
  public newInstitut: FormGroup;
  public changeStatusInstitut = false;
  public submitted = false;
  public id: any;
  navExtras: NavigationExtras;
  userType:string = '';

  constructor(
    private router: Router,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    public validators: ValidatorsService,
    public authenticationService: AuthenticationService,
    public activeRoute: ActivatedRoute,
    public requestsService: RequestsService,
    public toastr: ToastrService,
    private shareData: SharedDataService,
    public dialog: MatDialog,
    private paginationRequest: RequestPaginationSuperstructureService
  ) {
    if(JSON.parse(localStorage.getItem('currentUser')).user_type == 'SuperAdmin' || JSON.parse(localStorage.getItem('currentUser')).user_type == 'CompanyAdmin') {
      this.userType = JSON.parse(localStorage.getItem('currentUser')).user_type;
      this.displayedColumns = ['name', 'played_games_count', 'action'];
    }
  }

  ngOnInit() {
    this.institutions.reverse();
    this.dataSource = new MatTableDataSource(this.institutions);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.initForm();
    this.getId();
  }

  initForm() {
    this.newInstitut = this.formBuilder.group({
      name: ['', [Validators.required, this.validators.textValidator]]
    })
    this.onChanges();
  }

  onChanges() {
    this.newInstitut.valueChanges.subscribe(
      val => {
        this.changeStatusInstitut = true;
      }
    )
  }

  createInstitut() {
    let value = this.newInstitut.value;
    this.submitted = true;
    if (!this.newInstitut.invalid) {
      let instit = {
        institution: {}
      }
      instit.institution['name'] = value.name;
      instit.institution['company_id'] = this.id;
      this.requestsService.postRequest('/institutions', instit).subscribe(
        data => {
          this.changeStatusInstitut = false;
          this.modalService.dismissAll();
          this.getNewListOfInstitut();
          this.toastr.success('Institution was created successfully', 'Congrats', {
            positionClass: 'toast-bottom-right'
          })
        }
      )
    }
  }

  getId() {
    this.activeRoute.paramMap.pipe(
      map(params => params.get('id'))).subscribe(
        data => {
          this.id = +data;
        }
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  goInstitutionPage(element) {
    this.navExtras = {
      state: {
        community: element.name
      }
    }
    this.router.navigate(['/home/company-info/company-facilities/' + this.id + '/dashboard/' + element.id],this.navExtras);
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  clearInput() {
    this.newInstitut.reset();
    this.changeStatusInstitut = false;
  }

  getNewListOfInstitut() {
    this.paginationRequest.getRequest('/companies/' + this.id + '/institutions').subscribe(
      data => {
        this.institutions = data;
        this.shareData.setData(this.institutions);
        this.institutions.reverse();
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.institutions);
        this.dataSource.paginator = this.paginator;
      }
    )
  }

  openDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this resident?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCommunity(element);
      }
    });
  }

  deleteCommunity(element) {
    this.requestsService.deleteRequest('/institutions/'+element.id).subscribe(
      (res:any)=>{
        this.toastr.success(res.message,'',{
          positionClass: 'toast-bottom-right'
        })
        this.getNewListOfInstitut();
      },
      err=>{
        this.toastr.error('Try again later','Error',{
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

  checkForDeletCommunity(element) {
    if(element && element.deleted_at) {
      return 'deletedObj'
    } else {
      return ''
    }
  }

}
