import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { RandomColor } from 'angular-randomcolor';

@Component({
  selector: 'app-game-type-item',
  templateUrl: './game-type-item.component.html',
  styleUrls: ['./game-type-item.component.scss']
})
export class GameTypeItemComponent implements OnInit {

  @Input() userGames = [];
  public newArrGames: any = [];
  displayedColumns: string[] = ['name', 'time_spent', 'status'];
  public dataSource: any;

  constructor() { }

  ngOnInit() {
    this.checkLastWeekGames();
  }

  filterGames(arr) {
    let game_id = 1;
    let games_arr = [];
    let pre_arr = [];
    let sortGameForId = arr.sort((a, b) => { return a.game_id - b.game_id });
    sortGameForId.forEach(game => {
      if (game_id == game.game_id) {
        pre_arr.push(game);
      } else {
        games_arr.push(pre_arr);
        game_id = game.game_id
        pre_arr = [];
        pre_arr.push(game)
      }
    })
    games_arr.push(pre_arr);
    this.newArrGames = games_arr;
    this.newArrGames.sort((a, b) => { return b.length - a.length })
    this.drawDiagram();
  }

  drawDiagram() {
    let top3Games = [];
    let top3GamesForTable = [];
    let top3GamesForDiagram = [];
    if (this.newArrGames.length >= 3) {
      top3Games.push(this.newArrGames[0]);
      top3Games.push(this.newArrGames[1]);
      top3Games.push(this.newArrGames[2]);
      this.createObjForDiagram(top3Games, top3GamesForDiagram, top3GamesForTable);
      this.dataSource = new MatTableDataSource(top3GamesForTable);
    } else {
      top3Games = this.newArrGames;
      this.createObjForDiagram(top3Games, top3GamesForDiagram, top3GamesForTable);
      this.dataSource = new MatTableDataSource(top3GamesForTable);
    }
  }

  createObjForDiagram(top3Games, top3GamesForDiagram, top3GamesForTable) {
    top3Games.forEach(game => {
      let totalTimForGame = this.timeMinRes(this.countTime(game)) == '00' ? this.timeMinRes(this.countTime(game)) + ' : ' + this.timeSecRes(this.countTime(game)) : this.timeMinRes(this.countTime(game));
      let randColor = RandomColor.generateColor();
      let objForDiagram = {};
      objForDiagram['length'] = game.length;
      objForDiagram['color'] = randColor;
      top3GamesForDiagram.push(objForDiagram)
      let objForTable = {};
      game.forEach(elem => {
        let name = this.getBodyOfGame(elem);
        if (this.getBodyOfGame(elem)) {
          objForTable['name'] = name.game_name;
          objForTable['status'] = 'Stable';
          objForTable['time_spent'] = totalTimForGame + ' min';
          objForTable['color'] = randColor;
        }
      })
      top3GamesForTable.push(objForTable);
    });
    this.increaseArray(top3GamesForDiagram);
  }

  getBodyOfGame(elem) {
    let body = JSON.parse(elem.body);
    return body;
  }

  increaseArray(games) {
    for (let i = 0; i < games.length; i++) {
      let id = 'column' + i;
      let count = (games[i].length / (10 + games[i].length)) * 170;
      setTimeout(() => {
        document.getElementById(id).style.height = Math.round(count) + 'px';
        document.getElementById(id).style.backgroundColor = games[i].color;
      })
    }
  }

  checkLastWeekGames() {
    // Set start of last week to startLastWeek variable
    let startLastWeek = new Date();
    let dayWeekAgo = startLastWeek.getDate() - 7;
    startLastWeek.setDate(dayWeekAgo);
    let date = startLastWeek.getDate() - startLastWeek.getDay() + 1;
    startLastWeek.setDate(date);

    //Set end of last week to endLastWeek variable
    let endLastWeek = new Date();
    endLastWeek.setDate(startLastWeek.getDate() + 6);

    let arrOfActualGames = [];
    this.userGames.forEach( game => {
      let dateOfGame = new Date(game.created_at)
      if (dateOfGame > startLastWeek && dateOfGame <= endLastWeek) {
        arrOfActualGames.push(game);
      }
    });
    if(arrOfActualGames.length) {
      this.filterGames(arrOfActualGames);
    }
  }

  countTime(game) {
    let totalTimeSec = 0;
    game.forEach(elem => {
      let name = this.getBodyOfGame(elem);
      name.rounds.forEach( round => {
        totalTimeSec += +round.round_time;
      });
    });
    return totalTimeSec;
  }

  timeMinRes(totalTimeSec) {
    let timeMin: any = Math.floor(totalTimeSec / 60);
    return timeMin;
  }

  timeSecRes(totalTimeSec) {
    let timeSec: any = 0;
    if (totalTimeSec % 60 < 10) {
      timeSec = '0' + totalTimeSec % 60;
      return timeSec;
    } else {
      timeSec = totalTimeSec % 60;
      return timeSec;
    }
  }
}
