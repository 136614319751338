import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidatorsService } from 'src/app/services/validators.service';
import { RequestsService } from 'src/app/services/requests.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-manage-admin',
  templateUrl: './manage-admin.component.html',
  styleUrls: ['./manage-admin.component.scss']
})
export class ManageAdminComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public submitted = false;
  public changeStatusAdmin = false;
  public cantDelete = false;
  public institutList: any = [];
  public companyAdmins = [];
  public institutionsAdmins = [];
  public companyList: any = [];
  public userWrap = {
    user: {}
  };
  public editAdminHeader: any;
  public today = new Date();
  public newAdmin: FormGroup;
  public adminType: any;
  public dataSourceCompany: any;
  public dataSourceFacility: any;
  public admins: any = [];
  displayedColumnsCompany: string[] = ['admin', 'email', 'company', 'action'];
  displayedColumnsFacility: string[] = ['admin', 'email', 'facility', 'company', 'action'];
  @ViewChild('institutPaginator', { static: true }) institutPaginator: MatPaginator;
  @ViewChild('companyPaginator', { static: true }) companyPaginator: MatPaginator;

  constructor(
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    public validators: ValidatorsService,
    private requestsService: RequestsService,
    public authenticationService: AuthenticationService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private paginationRequest: RequestPaginationSuperstructureService
  ) { }

  ngOnInit() {
    if (this.currentUser.user_type == 'InstitutionAdmin') {
      this.displayedColumnsFacility = ['admin', 'email', 'action'];
    } else if (this.currentUser.user_type == 'CompanyAdmin') {
      this.displayedColumnsCompany = ['admin', 'email', 'action'];
      this.displayedColumnsFacility = ['admin', 'email', 'facility', 'action'];
    }
    this.getCompanyList();
  }

  getCompanyList() {
    this.paginationRequest.getRequest('/companies').subscribe(
      data => {
        this.companyList = data;
        this.getInstitutionList();
      },
      err => {
        this.toastr.error('Can`t get companies,try again later.', 'Oops', {
          positionClass: 'toast-bottom-right'
        })
      }
    );
  }

  getInstitutionList() {
    this.paginationRequest.getRequest('/institutions').subscribe(
      data => {
        this.institutList = data;
        this.getAdmins();
      },
      err => {
        this.toastr.error('Can`t get institutions,try again later.', 'Oops', {
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

  getAdmins() {
    this.paginationRequest.getRequest('/admins').subscribe(
      data => {
        this.admins = data;
        this.setNameOfFacility();
      }
    )
  }

  setNameOfFacility() {
    this.admins.forEach(admin => {
      if (admin.id == this.currentUser.id) {
        this.cantDelete = true;
      }
      if (admin.type == 'CompanyAdmin' && this.companyList) {
        this.companyList.forEach(company => {
          if (admin.user_company_id == company.id) {
            admin['company_name'] = company.name;
          }
        })
        this.companyAdmins.push(admin)
      } else if (admin.type == 'InstitutionAdmin' && this.institutList) {
        this.institutList.forEach(institut => {
          if (admin.institution_id == institut.id) {
            this.requestsService.getRequest('/institutions/' + admin.institution_id).subscribe(
              data => {
                admin['company_id'] = data['company_id'];
                this.companyList.forEach(company => {
                  if (admin.company_id == company.id) {
                    admin['company_name'] = company.name;
                  };
                })
              }
            )
            admin['institution_name'] = institut.name;
          }
        });
        this.institutionsAdmins.push(admin)
      }
    });
    this.companyAdmins.reverse();
    this.dataSourceCompany = new MatTableDataSource(this.companyAdmins);
    this.dataSourceCompany.paginator = this.companyPaginator;
    this.institutionsAdmins.reverse();
    this.dataSourceFacility = new MatTableDataSource(this.institutionsAdmins);
    this.dataSourceFacility.paginator = this.institutPaginator;
  }

  getInstitutInfo(id) {
    this.requestsService.getRequest('/institutions/' + id).subscribe(
      data => {
        this.userWrap.user['company_id'] = data['company_id'];
      }
    )
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
    this.initForm();
  }

  openVerticallyCenteredEdit(content, element) {
    this.modalService.open(content, { centered: true });
    this.initEditForm(element);
  }

  initEditForm(element) {
    this.editAdminHeader = element.type;
    this.adminType = element.type;
    this.newAdmin = this.formBuilder.group({
      first_name: [element.first_name, [Validators.required, this.validators.textValidator]],
      last_name: [element.last_name, [Validators.required, this.validators.textValidator]],
      email: [element.email, [Validators.required, this.validators.emailValidator]],
      type: [element.type, Validators.required],
      company_id: [element.user_company_id],
      institution_id: [element.institution_id],
      id: element.id
    });
    this.onChanges();
  }

  initForm() {
    this.editAdminHeader = null;
    this.newAdmin = this.formBuilder.group({
      first_name: ['', [Validators.required, this.validators.textValidator]],
      last_name: ['', [Validators.required, this.validators.textValidator]],
      email: ['', [Validators.required, this.validators.emailValidator]],
      type: ['', Validators.required],
      company_id: [''],
      institution_id: ['']
    })
    this.onChanges();
  }

  onChanges() {
    this.newAdmin.valueChanges.subscribe(
      val => {
        this.changeStatusAdmin = true;
        this.adminType = val.type;
        if (val.type == 'CompanyAdmin') {
          this.newAdmin.controls['company_id'].setValidators(Validators.required);
          if (this.newAdmin.controls.institution_id.validator) {
            this.newAdmin.controls.institution_id.clearValidators();
            this.newAdmin.controls.institution_id.updateValueAndValidity();
          }
        } else if (val.type == 'InstitutionAdmin') {
          this.newAdmin.controls['institution_id'].setValidators(Validators.required);
          if (this.newAdmin.controls.company_id.validator) {
            this.newAdmin.controls.company_id.clearValidators();
            this.newAdmin.controls.company_id.updateValueAndValidity();
          }
        }
      }
    )
  }

  applyFilterCompany(filterValue: string) {
    this.dataSourceCompany.filter = filterValue.trim().toLocaleLowerCase();
  }

  applyFilterFacility(filterValue: string) {
    this.dataSourceFacility.filter = filterValue.trim().toLocaleLowerCase();
  }

  putAdmin() {
    this.addFieldsToAdmin();
    let id = this.newAdmin.controls.id.value;
    this.requestsService.putRequest('/users/' + id, this.userWrap).subscribe(
      data => {
        this.paginationRequest.getRequest('/admins').subscribe(
          data => {
            this.admins = data;
            this.companyAdmins = [];
            this.institutionsAdmins = [];
            this.setNameOfFacility();
            this.modalService.dismissAll();
            this.clearInput();
            this.toastr.success('Admin was edit successfully', 'Congrats', {
              positionClass: 'toast-bottom-right'
            });
          },
          err => {
            for (let key in err) {
              this.toastr.error(key + ' ' + err[key], 'Error', {
                positionClass: 'toast-bottom-right'
              })
            }
          }
        )
      },
      err => {
        for (let key in err) {
          this.toastr.error(key + ' ' + err[key], 'Error', {
            positionClass: 'toast-bottom-right'
          })
        }
      }
    )
  }

  createAdmin() {
    this.submitted = true;
    if (!this.newAdmin.invalid) {
      this.addFieldsToAdmin();
      this.requestsService.postRequest('/users', this.userWrap).subscribe(
        res => {
          this.paginationRequest.getRequest('/admins').subscribe(
            data => {
              this.admins = data;
              this.companyAdmins = [];
              this.institutionsAdmins = [];
              this.setNameOfFacility();
              this.modalService.dismissAll();
              this.clearInput();
              this.toastr.success('Admin was created successfully', 'Congrats', {
                positionClass: 'toast-bottom-right'
              })
            }
          )
        },
        err => {
          for (let error in err) {
            this.toastr.error(error + ' ' + err[error], 'Error', {
              positionClass: 'toast-bottom-right'
            });
          }
        }
      )
    }
  }

  clearInput() {
    this.newAdmin.reset();
    this.submitted = false;
    this.changeStatusAdmin = false;
  }

  addFieldsToAdmin() {
    let userWrap = {
      user: {}
    }
    if (this.adminType == 'CompanyAdmin') {
      userWrap.user['email'] = this.newAdmin.value.email;
      userWrap.user['first_name'] = this.newAdmin.value.first_name;
      userWrap.user['last_name'] = this.newAdmin.value.last_name;
      userWrap.user['password'] = 'welcome1';
      userWrap.user['password_confirmation'] = 'welcome1';
      userWrap.user['type'] = this.newAdmin.value.type;
      userWrap.user['company_id'] = +this.newAdmin.value.company_id;
    } else if (this.adminType == 'InstitutionAdmin') {
      userWrap.user['email'] = this.newAdmin.value.email;
      userWrap.user['first_name'] = this.newAdmin.value.first_name;
      userWrap.user['institution_id'] = parseInt(this.newAdmin.value.institution_id);
      userWrap.user['last_name'] = this.newAdmin.value.last_name;
      userWrap.user['password'] = 'welcome1';
      userWrap.user['password_confirmation'] = 'welcome1';
      userWrap.user['type'] = this.newAdmin.value.type;
      this.getInstitutInfo(this.newAdmin.value.institution_id);
    }
    this.userWrap = userWrap;
  }

  openDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this admin?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletPatient(element);
      }
    });
  }

  deletPatient(element) {
    this.requestsService.deleteRequest('/users/' + element.id).subscribe(
      data => {
        this.paginationRequest.getRequest('/admins').subscribe(
          data => {
            this.admins = data;
            this.institutionsAdmins = [];
            this.companyAdmins = [];
            this.setNameOfFacility();
            this.toastr.success('Deleted successfully', 'Congrats', {
              positionClass: 'toast-bottom-right'
            });
          }
        )
      }
    )
  }

}
