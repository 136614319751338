import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/services/requests.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ValidatorsService } from 'src/app/services/validators.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss']
})
export class EditPatientComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public currentUserStatus = false;
  public changeStatusSenior = false;
  public changeStatusRelative = false;
  public newRelativeStatus = false;
  public relativeIndex: number;
  private id: number;
  private relatives = [];
  public currentUser: any;
  private relativeId: any;
  public family: any;
  public fullUserInfo: any;
  private relativeWrapp: any;
  private userWrapp: any;
  public newRelativeIndex = [];
  private newRelativeWrapp = {
    offspring: {}
  };
  public maxDate: any;
  private subscr: Subscription;
  savingInProcess: number;
  disableAddBtn = false;
  pagePosition = 'Down';


  constructor(
    public requestService: RequestsService,
    public authenticationService: AuthenticationService,
    public formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private validators: ValidatorsService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getId();
    this.setMaxDay();
  }

  scrollPageEvent(event) {}

  setMaxDay() {
    let today = new Date();
    this.maxDate = today;
  }

  getId() {
    this.activeRoute.paramMap.pipe(
      map(params => params.get('id'))).subscribe(
        data => {
          this.id = +data;
          this.getSenior();
        }
      );
  }

  getSenior() {
    let user: any;
    this.requestService.getRequest('/seniors/' + `${this.id}`).subscribe(
      data => {
        this.fullUserInfo = data;
        user = data;
        this.family = user.offsprings;
        this.currentUser = user.user;
        this.currentUserStatus = true;
        this.initForm(this.currentUser);
        this.onChanges();
      }
    )
  }

  onChanges() {
    this.subscr = this.authenticationService.registerForm.valueChanges.subscribe(
      val => {
        this.checkSeniorFields()
      }
    );
    this.getIndexOfRelative();
  }

  initForm(user) {
    this.authenticationService.registerForm = this.formBuilder.group({
      name: [user.first_name, [Validators.required, this.validators.textValidator]],
      surname: [user.last_name, [Validators.required, this.validators.textValidator]],
      date: [user.dob, [Validators.required]],
      type: 'Senior',
      email: [user.email, [Validators.required, this.validators.emailValidator]]
    });
    this.authenticationService.registerForm.setControl('relatives', this.setExistingFields(this.family));
  }

  setExistingFields(family): FormArray {
    const formArray = new FormArray([]);
    family.forEach(elem => {
      formArray.push(this.formBuilder.group({
        relationship: [elem.relationship, [Validators.required, this.validators.textValidator]],
        contact_number_relative: [elem.phone],
        email: [elem.email, [Validators.required, this.validators.emailValidator]],
        family_id: elem.family_id,
        first_name: [elem.first_name, [Validators.required, this.validators.textValidator]],
        id: elem.id,
        last_name: [elem.last_name, [Validators.required, this.validators.textValidator]],
        type: 'Offspring'
      }));
    })
    return formArray
  }

  checkSeniorFields() {
    for (let key in this.authenticationService.registerForm.controls) {
      if (!this.authenticationService.registerForm.controls[key].pristine && key !== 'relatives') {
        this.changeStatusSenior = true;
        this.addFieldsToSenior();
      }
      else if (!this.authenticationService.registerForm.controls[key].pristine && key == 'relatives' && this.relativeIndex || this.relativeIndex == 0) {
        this.checkExactRelative();
      }
    }
  }

  checkExactRelative() {
    this.changeStatusRelative = true;
    let relativeFields = this.formData.controls[this.relativeIndex].value;
    this.addFieldsToRelative(relativeFields);
  }

  getIndexOfRelative() {
    this.formData.controls.forEach(control => {
      control.valueChanges.subscribe(val => {
        let i = this.formData.controls.indexOf(control);
        this.relativeIndex = i;
      })
    })
  }

  get formData() {
    return this.authenticationService.registerForm.get('relatives') as FormArray;
  }

  relativeControls(i) {
    return this.formData.controls[i]['controls'];
  }

  addRelative() {
    this.newRelativeIndex = [];
    this.newRelativeStatus = true;
    // this.formData.insert(0,this.formBuilder.group({
    this.formData.push(this.formBuilder.group({
      relationship: ['', [Validators.required, this.validators.textValidator]],
      contact_number_relative: [''],
      type: 'Offspring',
      family_id: this.currentUser.family_id,
      last_name: ['', [Validators.required, this.validators.textValidator]],
      email: ['', [Validators.required, this.validators.emailValidator]],
      id: '',
      first_name: ['', [Validators.required, this.validators.textValidator]],
      password: ''
    }));

    //If fields are empty for just added relative,push to array for
    // multi saving
    let index;
    this.formData.controls.forEach(control => {
      if (!control.value.email && !control.value.first_name && !control.value.last_name) {
        index = this.formData.controls.indexOf(control);
        this.newRelativeIndex.push(index);
      }
    });

    // Scroll to just added new family member
    this.scrollToNewFamilyMember();
  }

  scrollToNewFamilyMember() {
    let element = document.getElementsByClassName('row-cont');
    setTimeout(() => {
      element[0].scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});

      //Disable button untill new relative will be saved
      this.disableAddBtn = true;
    }, 0);
  }

  removeRelative(i) {
    let id: any;
    for (let j = 0; j < this.authenticationService.registerForm.controls['relatives']['controls'].length; j++) {
      if (i == j) {
        id = this.authenticationService.registerForm.controls['relatives']['controls'][j].value.id;
        if (id) {
          this.requestService.deleteRequest('/users/' + id).subscribe(
            data => {
              this.formData.removeAt(i);
            }
          )
        } else if (!id) {
          let index = this.newRelativeIndex.indexOf(i);
          this.newRelativeIndex.splice(index, 1)
          this.formData.removeAt(i);

          //Make clickable add relative btn and move to top of form
          this.disableAddBtn = false;
          // this.scrollToTopRelativeForm();

          //check relative form for pristine and can't moove saving button to another form element
          this.formData.controls.forEach(control => {
            if (!control.pristine) {
              let index = this.formData.controls.indexOf(control);
              this.relativeIndex = index;
            }
          });

          //hide validation from relative form after remove just added relative
          this.savingInProcess = null;
        }
      }
    }
  }

  saveNewRelative(i) {
    this.savingInProcess = i;
    this.addFieldsToNewRelative(this.formData.controls[i].value);
    if (!this.formData.controls[i].invalid) {
      this.requestService.postRequest('/offsprings/', this.newRelativeWrapp).subscribe(
        (data: any) => {
          this.newRelativeStatus = false;
          this.formData.controls[i]['controls'].id.patchValue(data.id);

          // access to change just created relative(can change fields of just created relative)
          this.subscr.unsubscribe();
          this.onChanges();

          //hide save btn after adding new relative
          let findElem = this.newRelativeIndex.indexOf(i);
          this.newRelativeIndex.splice(findElem, 1);
          this.relativeIndex = null;

          //Made clickable add new relative button
          this.disableAddBtn = false;

          //Scroll to top relative form
          // this.scrollToTopRelativeForm();

          this.toastr.success('Family Member was added successfully', 'Congrats', {
            closeButton: true,
            positionClass: 'toast-bottom-right'
          });
        },
        err => {
          for (let key in err) {
            this.toastr.error(key + ' ' + err[key], 'Oops', {
              closeButton: true,
              positionClass: 'toast-bottom-right'
            })
          };
          //Made clickable add new relative button
          this.disableAddBtn = false;
        }
      )
    }
  }

  // scrollToTopRelativeForm() {
  //   let element = document.getElementsByClassName('header-name');
  //   setTimeout(() => {
  //     let firstElem = element[0];
  //     firstElem.scrollIntoView();
  //   }, 0);
  // }

  addFieldsToNewRelative(info) {
    this.newRelativeWrapp.offspring['phone'] = info.contact_number_relative;
    this.newRelativeWrapp.offspring['email'] = info.email;
    this.newRelativeWrapp.offspring['last_name'] = info.last_name;
    this.newRelativeWrapp.offspring['relationship'] = info.relationship;
    this.newRelativeWrapp.offspring['type'] = info.type;
    this.newRelativeWrapp.offspring['first_name'] = info.first_name;
    this.newRelativeWrapp.offspring['senior_id'] = this.id;
    this.newRelativeWrapp.offspring['password'] = info.last_name + 123;
  }

  addFieldsToRelative(info) {
    let id: any;
    let relativeWrapp = {
      user: {}
    }
    relativeWrapp.user['id'] = info.id;
    relativeWrapp.user['type'] = info.type;
    relativeWrapp.user['family_id'] = info.family_id;
    relativeWrapp.user['relationship'] = info.relationship;
    relativeWrapp.user['phone'] = info.contact_number_relative;
    relativeWrapp.user['first_name'] = info.first_name;
    relativeWrapp.user['last_name'] = info.last_name;
    relativeWrapp.user['email'] = info.email;
    this.relativeId = info.id;
    this.relativeWrapp = relativeWrapp;
  }

  addFieldsToSenior() {
    let userWrapp = {
      user: {}
    };
    this.setUserDob(userWrapp.user, this.authenticationService.registerForm.controls.date.value)
    userWrapp.user['email'] = this.authenticationService.registerForm.controls.email.value;
    userWrapp.user['first_name'] = this.authenticationService.registerForm.controls.name.value;
    userWrapp.user['last_name'] = this.authenticationService.registerForm.controls.surname.value;
    userWrapp.user['institution_id'] = this.currentUser.institution_id;
    userWrapp.user['family_id'] = this.currentUser.family_id;
    userWrapp.user['type'] = this.authenticationService.registerForm.controls.type.value;
    userWrapp.user['id'] = this.currentUser.id;
    this.userWrapp = userWrapp
  }

  setUserDob(user, value) {
    let day = new Date(value).getDate();
    let year = new Date(value).getFullYear();
    let month = new Date(value).getMonth();
    user['dob'] = year + '-' + (month + 1) + '-' + day;
  }

  saveSeniorChanges() {
    this.submitted = true;
    if (!this.authenticationService.registerForm.invalid) {
      this.requestService.putRequest('/users/' + this.id, this.userWrapp).subscribe(
        data => {
          this.submitted = false;
          this.toastr.success('Changes were saved', 'Congrats', {
            closeButton: true,
            positionClass: 'toast-bottom-right'
          })
          this.changeStatusSenior = false;
          this.authenticationService.registerForm.markAsPristine();
        },
        err => {
          for (let key in err) {
            this.toastr.error(key + ' ' + err[key], 'Error', {
              closeButton: true,
              positionClass: 'toast-bottom-right'
            })
          }
        }
      )
    }
  }

  saveRelativeChanges() {
    this.submitted = true;
    if (this.formData.controls[this.relativeIndex]['controls'].id.value) {
      if (!this.formData.controls[this.relativeIndex].invalid) {
        this.requestService.putRequest('/users/' + this.relativeId, this.relativeWrapp).subscribe(
          data => {
            this.toastr.success('Changes were saved', 'Congrats', {
              closeButton: true,
              positionClass: 'toast-bottom-right'
            });
            this.submitted = false;
            this.changeStatusRelative = false;
            this.relativeIndex = null;
            this.formData.markAsPristine();
          },
          err => {
            for (let key in err) {
              this.toastr.error(key + ' ' + err[key], 'Error', {
                closeButton: true,
                positionClass: 'toast-bottom-right'
              })
            }
          }
        )
      }
    } else if (!this.formData.controls[this.relativeIndex]['controls'].id.value) {
      this.saveNewRelative(this.relativeIndex);
    }
  }

  openDialog(i): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this family member?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRelative(i);
      }
    });
  }

  checkRelativeStatus(i) {
    let element = this.formData.controls[i];
    if (element.value.id) {
      return false
    } else if (!element.value.id) {
      return true
    }
  }

  checkSubmittedForRelative(i) {
    if (i == this.savingInProcess) {
      return true;
    } else {
      return false;
    }
  }

  scrollFunction() {
    let element = document.getElementsByClassName('row-cont');
    if(this.pagePosition == 'Down') {
      element[0].scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
      this.pagePosition = 'Up';
    } else if(this.pagePosition == 'Up') {
      element[0].scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
      this.pagePosition = 'Down';
    }
  }

}
