import { Component } from '@angular/core';
import { ProgressbarService } from './services/progressbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: any;

  constructor(
    public progressiveBar: ProgressbarService
  ) {
    this.progressiveBar.isLoading.subscribe((status) => {
      this.loading = status;
    });
   }

}
