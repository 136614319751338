import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const value = JSON.parse(localStorage.getItem('currentUser'));
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && value && value.auth_token) {
      const headers = new HttpHeaders({
        Authorization: 'Bearer ' + `${value.auth_token}`
      });
      const cloneReq = request.clone({ headers });
      return next.handle(cloneReq);
    } else {
      return next.handle(request);
    }
  }
}
