import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatTableDataSource, MatDialog } from '@angular/material'
import { MatPaginator } from '@angular/material'
import { RequestsService } from 'src/app/services/requests.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidatorsService } from 'src/app/services/validators.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatSort} from '@angular/material/sort';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-company-statistic',
  templateUrl: './company-statistic.component.html',
  styleUrls: ['./company-statistic.component.scss']
})
export class CompanyStatisticComponent implements OnInit {

  @Input() companiesList: any = [];
  displayedColumns: string[] = ['name', 'played_games_count'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource: any;
  public facilityListOfCompany: any;
  public id: any;
  public newCompany: FormGroup;
  public changeStatusCompany = false;
  public submitted = false;
  navExtras: NavigationExtras;
  userType:string = '';

  constructor(
    private router: Router,
    private requestsService: RequestsService,
    public activeRoute: ActivatedRoute,
    public shareData: SharedDataService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    public validators: ValidatorsService,
    public toastr: ToastrService,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private paginationRequest: RequestPaginationSuperstructureService
  ) { 
    if(JSON.parse(localStorage.getItem('currentUser')).user_type == 'SuperAdmin') {
      this.userType = JSON.parse(localStorage.getItem('currentUser')).user_type;
      this.displayedColumns = ['name', 'played_games_count', 'action'];
    }
  }

  ngOnInit() {
    this.companiesList.reverse();
    this.dataSource = new MatTableDataSource(this.companiesList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator
    this.initForm();
  }

  initForm() {
    this.newCompany = this.formBuilder.group({
      name: ['', [Validators.required, this.validators.textValidator]]
    })
    this.onChanges();
  }

  onChanges() {
    this.newCompany.valueChanges.subscribe(
      val => {
        this.changeStatusCompany = true;
      }
    )
  }

  clearInput() {
    this.newCompany.reset();
    this.changeStatusCompany = false;
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  goInstitutionPage(element) {
    this.navExtras = {
      state: {
        company: element.name
      }
    }
    this.router.navigate(['/home/company-info/company-facilities/' + element.id],this.navExtras);
  }

  createCompany() {
    let value = this.newCompany.value;
    this.submitted = true;
    if (!this.newCompany.invalid) {
      let obj = {
        company: {}
      }
      obj.company['name'] = value.name;
      this.requestsService.postRequest('/companies', obj).subscribe(
        data => {
          this.changeStatusCompany = false;
          this.modalService.dismissAll();
          this.getNewListOfCompanies();
          this.toastr.success('Company was created successfully', 'Congrats', {
            positionClass: 'toast-bottom-right'
          })
        }
      );
    }
  }

  getNewListOfCompanies() {
    this.paginationRequest.getRequest('/companies').subscribe(
      data => {
        this.companiesList = data;
        this.requestsService.setData(this.companiesList);
        this.companiesList.reverse();
        this.dataSource = new MatTableDataSource(this.companiesList);
        this.dataSource.paginator = this.paginator;
      }
    )
  }

  openDialog(element): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this resident?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCompany(element);
      }
    });
  }

  deleteCompany(element) {
    this.requestsService.deleteRequest('/companies/'+element.id).subscribe(
      (res:any)=>{
        this.toastr.success(res.message,'',{
          positionClass: 'toast-bottom-right'
        })
        this.getNewListOfCompanies();
      },
      err=>{
        this.toastr.error('Try again later','Error',{
          positionClass: 'toast-bottom-right'
        })
      }
    )
  }

  checkForDeletCompany(element) {
    if(element.deleted_at) {
      return 'deletedObj'
    } else {
      return ''
    }
  }

}
