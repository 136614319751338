import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ProgressBarInterceptor } from './helpers/progressbar.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken, Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmEqualValidatorDirective} from '../app/helpers/confirm-equal-validator.derective';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { GamesStatsListComponent } from './components/games-stats-list/games-stats-list.component';
import { GameTypeItemComponent } from './components/game-type-item/game-type-item.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PatientDetailsComponent } from './components/patient-details/patient-details.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { CompanyDiagramComponent } from './components/company-diagram/company-diagram.component';
import { CompanyStatisticComponent } from './components/company-statistic/company-statistic.component';
import { CreateSeniorComponent } from './components/create-senior/create-senior.component';
import { EditPatientComponent } from './components/edit-patient/edit-patient.component';
import { RelativeTableComponent } from './components/relative-table/relative-table.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { HomeComponent } from './pages/home/home.component';
import { OffspringsComponent } from './pages/offsprings/offsprings.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule , MatSortModule, MatNativeDateModule ,MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatPaginatorModule, MatTableModule, MatDialogModule} from '@angular/material';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InstitutionsForCompanyComponent } from './components/institutions-for-company/institutions-for-company.component';
import { InstitutionsForCompanyStatisticComponent } from './components/institutions-for-company-statistic/institutions-for-company-statistic.component';
import { InstitutionsForCompanyDiagramComponent } from './components/institutions-for-company-diagram/institutions-for-company-diagram.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ManageAdminComponent } from './components/manage-admin/manage-admin.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { FeelingStatisticComponent } from './components/feeling-statistic/feeling-statistic.component';
import { NgxMaskModule } from 'ngx-mask'
import * as Rollbar from 'rollbar';
import { MobileWarnComponent } from './components/mobile-warn/mobile-warn.component';

const rollbarConfig = {
  accessToken: '4634770dd4b2433ab920d69929d64d13',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err: any): void {
    var rollbar = this.injector.get(RollbarService);
    rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ConfirmEqualValidatorDirective,
    GamesStatsListComponent,
    GameTypeItemComponent,
    DashboardComponent,
    PatientDetailsComponent,
    CompanyInfoComponent,
    CompanyDiagramComponent,
    CompanyStatisticComponent,
    CreateSeniorComponent,
    EditPatientComponent,
    RelativeTableComponent,
    MyProfileComponent,
    HomeComponent,
    OffspringsComponent,
    RedirectComponent,
    InstitutionsForCompanyComponent,
    InstitutionsForCompanyStatisticComponent,
    InstitutionsForCompanyDiagramComponent,
    ConfirmationDialogComponent,
    ManageAdminComponent,
    BreadCrumbComponent,
    FeelingStatisticComponent,
    MobileWarnComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgbModule,
    MatDialogModule
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [
    MatDatepickerModule,
    MatPaginatorModule,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true},
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
