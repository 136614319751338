import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { PasswordValidatorService } from '../../services/password-validator.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ValidatorsService } from 'src/app/services/validators.service';
import { RequestsService } from 'src/app/services/requests.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public userWrap = {
    user: {}
  };
  public correctData = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    private passwordValidator: PasswordValidatorService,
    public validators: ValidatorsService,
    private requestsService: RequestsService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.getUserInfo(this.authenticationService.currentUserValue);
    }
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.authenticationService.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, this.validators.emailValidator]],
      password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator.passwordValidator]]
    });
  }

  onSubmit() {
    this.userWrap.user = this.authenticationService.registerForm.value;
    this.submitted = true;
    // stop here if form is invalid
    if (!this.authenticationService.registerForm.invalid) {
      this.loading = true;
      this.authenticationService.login(this.userWrap).pipe(first()).subscribe(
        data => {
          this.getUserInfo(data);
        },
        error => {
          this.correctData = false;
          this.loading = false;
        }
      );
    }
  }

  getUserInfo(data) {
    this.requestsService.getRequest('/users/' + data.user.id).subscribe(
      info => {
        if (data.user_type == 'SuperAdmin') {
          this.router.navigate(['home/company-info'])
        } else if (data.user.user_company_id && data.user_type == 'CompanyAdmin') {
          this.router.navigate(['/home/company-info/company-facilities/' + data.user.user_company_id])
        } else if (data.user.institution_id && data.user.institution_id !== 0 && data.user_type == 'InstitutionAdmin') {
          this.router.navigate(['/home/company-info/company-facilities/' + info['user_company_id'] + '/dashboard/' + data.user.institution_id])
        }
      }
    )
  }
}
