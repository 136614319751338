import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }

  emailValidator(control: FormControl) {
    const value = control.value;
    const patern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
    if(!patern) {
      return { invalidEmail: 'Email is not correct'};
    } else {
      return null;
    }
  }

  numberValidator(control: FormControl) {
    const value = control.value;
    const number = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(value);
    if (!number) {
      return { invalidPassword: 'Phone number is not correct.' };
    }
    return null;
  }

  textValidator(control: FormControl) {
    const value = control.value;
    const text = /^[a-zA-Zа-яА-ЯёЁ'][a-zA-Z-а-яА-ЯёЁ' ]+[a-zA-Zа-яА-ЯёЁ']?$/.test(value)
    if(!text) {
      return { invalid: 'Only letters allowed. More than 2 letters.'}
    } else {
      return null
    }
  }
}
