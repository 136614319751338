import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-games-stats-list',
  templateUrl: './games-stats-list.component.html',
  styleUrls: ['./games-stats-list.component.scss']
})
export class GamesStatsListComponent implements OnInit {

  @Input() userGames = [];
  displayedColumns: string[] = ['name', 'date', 'time_spent', 'correct', 'incorrect'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource: any;

  constructor() { }

  ngOnInit() {
    this.parseGames();
  }

  parseGames() {
    let newArrOfGames = [];
    this.userGames.forEach(game => {
      this.createNewGameObject(game, newArrOfGames);
    });
    newArrOfGames.reverse();
  }

  createNewGameObject(game, newArrOfGames) {
    let obj = {
      name: '',
      date: '',
      time: '',
      posAnswers: 0,
      negAnswers: 0
    };
    this.getFullDate(game);
    const body = JSON.parse(game.body);
    const gameTime = this.countGameTime(body);
    obj = {
      name: body.game_name ? body.game_name : 'N/A',
      date: game.time ? game.time : 'N/A',
      time: gameTime,
      posAnswers: this.countPosAnswers(game),
      negAnswers: this.countNegAnswers(game)
    };
    newArrOfGames.push(obj);
    this.dataSource = new MatTableDataSource(newArrOfGames);
    this.dataSource.paginator = this.paginator;
  }

  countGameTime(body) {
    let gameTime: any;
    if (body.rounds && body.rounds.length) {
      let totalTimeSec = 0;
      body.rounds.forEach(round => {
        totalTimeSec += +round.round_time;
      });
      gameTime = this.timeHoursRes(totalTimeSec) + ' : ' + this.timeMinRes(totalTimeSec) + ' : ' + this.timeSecRes(totalTimeSec);
    } else {
      gameTime = 'N/A';
    }
    return gameTime;
  }

  getFullDate(game) {
    let fullDate: any;
    let UTC = new Date(game['created_at']).getTimezoneOffset() / 60;
    let date = new Date(game['created_at']).getMonth() + 1 + '/' + new Date(game['created_at']).getDate() + '/' + new Date(game['created_at']).getFullYear();
    let time = new Date(game['created_at']).getHours() + UTC + ':' + new Date(game['created_at']).getMinutes();
    if (new Date(game['created_at']).getHours() + UTC >= 13) {
      if (new Date(game['created_at']).getHours() + UTC == 13) {
        fullDate = date + ' ' + 1 + ':' + new Date(game['created_at']).getMinutes() + 'pm';
        game['time'] = fullDate;
      } else if (new Date(game['created_at']).getHours() + UTC >= 14) {
        fullDate = date + ' ' + (new Date(game['created_at']).getHours() + UTC - 12) + ':' + new Date(game['created_at']).getMinutes() + 'pm';
        game['time'] = fullDate;
      }
    } else {
      fullDate = date + ' ' + time + 'am';
      game['time'] = fullDate;
    }
  }

  timeMinRes(totalTimeSec) {
    let timeMin: any = Math.floor(totalTimeSec / 60);
    if (timeMin < 10) {
      timeMin = '0' + timeMin;
      return timeMin;
    }
    return timeMin;
  }

  timeHoursRes(totalTimeSec) {
    let timeHours: any = Math.floor(this.timeMinRes(totalTimeSec) / 60);
    if (timeHours < 10) {
      timeHours = '0' + timeHours;
      return timeHours;
    }
    return timeHours;
  }

  timeSecRes(totalTimeSec) {
    let timeSec: any = 0;
    if (totalTimeSec % 60 < 10) {
      timeSec = '0' + totalTimeSec % 60;
      return timeSec;
    } else {
      timeSec = totalTimeSec % 60;
      return timeSec;
    }
  }

  countPosAnswers(game) {
    let body = JSON.parse(game.body);
    if (!body.rounds || !body.rounds.length) {
      return 'N/A';
    }
    let bodyRounds = body.rounds;
    let posAnsw: any = 0;
    for (let i = 0; i < bodyRounds.length; i++) {
      if (bodyRounds[i].round_result && bodyRounds[i].round_result.length) {
        for (let j = 0; j < bodyRounds[i].round_result.length; j++) {
          if (bodyRounds[i].round_result[j] === 1) {
            posAnsw += 1;
          }
        }
      } else {
        posAnsw = 'N/A';
        return posAnsw;
      }
    }
    return posAnsw;
  }

  countNegAnswers(game) {
    let body = JSON.parse(game.body);
    if (!body.rounds || !body.rounds.length) {
      return 'N/A';
    }
    let bodyRounds = body.rounds;
    let negAnsw: any = 0;
    for (let i = 0; i < bodyRounds.length; i++) {
      if (bodyRounds[i].round_result && bodyRounds[i].round_result.length) {
        for (let j = 0; j < bodyRounds[i].round_result.length; j++) {
          if (bodyRounds[i].round_result[j] === 0) {
            negAnsw += 1;
          }
        }
      } else {
        negAnsw = 'N/A';
        return negAnsw;
      }
    }
    return negAnsw;
  }

}
