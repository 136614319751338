import { RequestsService } from './../../services/requests.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { PasswordValidatorService } from '../../services/password-validator.service';
import { ToastrService } from 'ngx-toastr';
import { ValidatorsService } from 'src/app/services/validators.service';
import { RequestPaginationSuperstructureService } from './../../services/request-pagination-superstructure.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  showCompanyList: TemplateRef<any> | null = null;
  showInstitutList: TemplateRef<any> | null = null;
  public loading = false;
  public submitted = false;
  public facilityList = [];
  public companyList: any = [];
  public userWrap = {
    user: {}
  };
  public valueFasilityList = true;
  public loadFasilityList = false;
  public userType: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    private passwordValidator: PasswordValidatorService,
    private requestService: RequestsService,
    private toastr: ToastrService,
    public validators: ValidatorsService,
    private paginationRequest: RequestPaginationSuperstructureService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.getUserInfo(this.authenticationService.currentUserValue);
    }
  }

  ngOnInit() {
    this.initForm();
    this.getInstitutionsList();
    this.getCompanyList();
    this.onChanges();
  }

  initForm() {
    this.authenticationService.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, this.validators.emailValidator]],
      institution_id: [''],
      password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator.passwordValidator]],
      password_confirmation: ['', Validators.required],
      type: ['', Validators.required],
      company_id: [''],
      first_name: 'John',
      last_name: 'Doe',
    });
  }

  onSubmit() {
    this.submitted = true;
    this.addFieldsToUser();
    // stop here if form is invalid
    if (!this.authenticationService.registerForm.invalid) {
      this.loading = true;
      this.registrationSenior();
    }
  }

  onChanges() {
    this.authenticationService.registerForm.valueChanges.subscribe(
      data => {
        this.userType = data.type;
        if (data.type == 'CompanyAdmin') {
          this.authenticationService.registerForm.controls['company_id'].setValidators(Validators.required);
          this.authenticationService.registerForm.controls['institution_id'].setValidators(null);
          this.authenticationService.registerForm.controls['institution_id'].setErrors(null);
        } else if (data.type == 'InstitutionAdmin') {
          this.authenticationService.registerForm.controls['institution_id'].setValidators(Validators.required);
          this.authenticationService.registerForm.controls['company_id'].setValidators(null);
          this.authenticationService.registerForm.controls['company_id'].setErrors(null);
        }
      }
    )
  }

  getInstitutionsList() {
    this.paginationRequest.getRequest('/institutions').subscribe(
      (res: any) => {
        this.facilityList = res;
        this.loadFasilityList = true;
        if (!this.facilityList.length) {
          this.valueFasilityList = false;
        }
      }
    );
  }

  getCompanyList() {
    this.paginationRequest.getRequest('/companies').subscribe(
      data => {
        this.companyList = data;
      }
    )
  }

  addFieldsToUser() {
    let userWrap = {
      user: {}
    }
    if (this.userType == 'CompanyAdmin') {
      userWrap.user['email'] = this.authenticationService.registerForm.value.email,
      userWrap.user['first_name'] = this.authenticationService.registerForm.value.first_name,
      userWrap.user['last_name'] = this.authenticationService.registerForm.value.last_name,
      userWrap.user['password'] = this.authenticationService.registerForm.value.password,
      userWrap.user['password_confirmation'] = this.authenticationService.registerForm.value.password_confirmation,
      userWrap.user['type'] = this.authenticationService.registerForm.value.type
      userWrap.user['company_id'] = +this.authenticationService.registerForm.value.company_id
    } else if (this.userType == 'InstitutionAdmin') {
      userWrap.user['email'] = this.authenticationService.registerForm.value.email,
      userWrap.user['first_name'] = this.authenticationService.registerForm.value.first_name,
      userWrap.user['institution_id'] = parseInt(this.authenticationService.registerForm.value.institution_id),
      userWrap.user['last_name'] = this.authenticationService.registerForm.value.last_name,
      userWrap.user['password'] = this.authenticationService.registerForm.value.password,
      userWrap.user['password_confirmation'] = this.authenticationService.registerForm.value.password_confirmation,
      userWrap.user['type'] = this.authenticationService.registerForm.value.type
    }
    this.userWrap = userWrap;
  }

  registrationSenior() {
    this.authenticationService.register(this.userWrap).pipe(first()).subscribe(
      data => {
        this.getUserInfo(data);
        this.toastr.success('Created successfully', 'Congrats', {
          positionClass: 'toast-bottom-right'
        })
      },
      error => {
        for (let i = 0; i < error.errors.length; i++) {
          for (let key in error.errors[i].detail) {
            this.toastr.error(`${key} ` + error.errors[i].detail[key], 'Oops', {
              positionClass: 'toast-bottom-right',
              closeButton: true
            })
          }
        }
        this.loading = false;
      }
    );
  }

  getUserInfo(data) {
    this.requestService.getRequest('/users/' + data.user.id).subscribe(
      info => {
        if (data.user.user_company_id && data.user_type == 'CompanyAdmin') {
          this.router.navigate(['/home/company-info/company-facilities/' + data.user.user_company_id])
        } else if (data.user.institution_id && data.user.institution_id !== 0 && data.user_type == 'InstitutionAdmin') {
          this.router.navigate(['/home/company-info/company-facilities/' + info['user_company_id'] + '/dashboard/' + data.user.institution_id])
        }
      }
    )
  }

  checkFasilityList() {
    if (this.valueFasilityList === false) {
      return 'disabled-register';
    }
  }

  colorDisabletButton() {
    if (this.valueFasilityList === false) {
      return 'disabled-register-button-color';
    }
  }
}
